import EventForm from "./EventForm";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {CircularProgress, circularProgressClasses} from "@mui/material";

function EditEvent({ games = [] }) {
    const { id } = useParams();
    const [event, setEvent] = useState(null);

    useEffect(() => {
        if (id) {
            axios.get(`/api/get/event/${id}`).then((response) => {
                setEvent(response.data);
            });
        }
    }, [id]);

    if (!event) {
        return null;
    }

    return <EventForm event={event} games={games} />;
}

export default EditEvent;
