import React, {useState, useEffect, useRef} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import {Autocomplete, FormControl, MenuItem, Select, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Editor} from "@tinymce/tinymce-react";
import {format} from 'date-fns';
import ShowSnack from "../../util/ShowSnack";
import SimpleBox from "../../util/SimpleBox";

const timeZones = [
    { value: 'UTC', label: 'UTC' },
    { value: 'GMT', label: 'GMT' },
    { value: 'Europe/London', label: 'Europe/London (GMT/BST)' },
    { value: 'America/New_York', label: 'America/New_York (ET)' },
    { value: 'America/Chicago', label: 'America/Chicago (CT)' },
    { value: 'America/Denver', label: 'America/Denver (MT)' },
    { value: 'America/Los_Angeles', label: 'America/Los_Angeles (PT)' },
    { value: 'Asia/Tokyo', label: 'Asia/Tokyo (JST)' },
    { value: 'Australia/Sydney', label: 'Australia/Sydney (AEST)' },
    { value: 'Europe/Paris', label: 'Europe/Paris (CET/CEST)' },
    { value: 'Asia/Kolkata', label: 'Asia/Kolkata (IST)' },
    { value: 'Asia/Singapore', label: 'Asia/Singapore (SGT)' },
    { value: 'America/Sao_Paulo', label: 'America/Sao_Paulo (BRT)' },
    { value: 'Pacific/Auckland', label: 'Pacific/Auckland (NZDT)' },
];

export default function EventForm({ event = null, games = [] }) {
    const editorRef = useRef(null);
    const [startDate, setStartDate] = useState(event ? new Date(event.event_start) : new Date());
    const [endDate, setEndDate] = useState(event ? new Date(event.event_end) : new Date());
    const [daysToAdd, setDaysToAdd] = useState(0);
    const [hoursToAdd, setHoursToAdd] = useState(0);

    const [inputs, setInputs] = useState({
        title: '',
        description: '',
        youtube_trailer: '',
        youtube_showcase: '',
        game_id: '',
        category: 0,
        active: 1,
        timeZone: 'GMT',
        start_est: 0,
        end_est: 0,
        recurring: 0
    });

    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    useEffect(() => {
        if (event) {
            setInputs({
                title: event.title,
                description: event.description,
                youtube_trailer: event.youtube_trailer,
                youtube_showcase: event.youtube_showcase,
                game_id: event.game_id,
                category: event.category,
                active: event.active,
                timeZone: event.timeZone || 'GMT',
                start_est: event.event_start_est,
                end_est: event.event_end_est,
                site_link: event.site_link,
                recurring: event.recurring,
                meta_description: event.meta_description,
                meta_keywords: event.meta_keywords,
            });
            setStartDate(new Date(event.event_start));
            setEndDate(new Date(event.event_end));
        }
    }, [event]);

    const handleCreate = (event) => {
        event.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }

        const formattedStartDate = format(startDate, "yyyy-MM-dd'T'HH:mm:ss");
        const formattedEndDate = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");

        // Append the UTC times to the form data
        formData.append("event_start", formattedStartDate);
        formData.append("event_end", formattedEndDate);

        if (event) formData.append("id", event.id);

        const url = '/api/create/event';
        const method = 'POST';

        axios({ method, url, data: formData })
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setSnackOpen(true);
                    setMessage(event ? 'Esemény sikeresen frissítve' : 'Sikeres esemény hozzáadás');
                    setSeverity('success');

                    if (!event) {
                        setInputs({
                            title: '',
                            description: '',
                            youtube_trailer: '',
                            youtube_showcase: '',
                            game_id: '',
                            category: 0,
                            active: 1,
                            timeZone: 'GMT',
                            start_est: 0,
                            end_est: 0,
                            recurring: 0,
                        });
                        setStartDate(new Date());
                        setEndDate(new Date());
                    }
                }
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }

        const formattedStartDate = format(startDate, "yyyy-MM-dd'T'HH:mm:ss");
        const formattedEndDate = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");

        // Append the UTC times to the form data
        formData.append("event_start", formattedStartDate);
        formData.append("event_end", formattedEndDate);

        if (event) formData.append("id", event.id);

        const url = `/api/edit/event`;
        const method = 'POST';

        axios({ method, url, data: formData })
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setSnackOpen(true);
                    setMessage(event ? 'Esemény sikeresen frissítve' : 'Sikeres esemény hozzáadás');
                    setSeverity('success');

                    if (!event) {
                        setInputs({
                            title: '',
                            description: '',
                            youtube_trailer: '',
                            youtube_showcase: '',
                            game_id: '',
                            category: 0,
                            active: 1,
                            timeZone: 'GMT',
                            start_est: 0,
                            end_est: 0,
                            recurring: 0,
                        });
                        setStartDate(new Date());
                        setEndDate(new Date());
                    }
                }
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    const handleAddTime = () => {
        const newEndDate = new Date(endDate);
        newEndDate.setDate(newEndDate.getDate() + parseInt(daysToAdd, 10));
        newEndDate.setHours(newEndDate.getHours() + parseInt(hoursToAdd, 10));
        setEndDate(newEndDate);
    };

    const toggleStartEst = () => {
        console.log("b: ", inputs.start_est);
        setInputs((prevInputs) => ({
            ...prevInputs,
            start_est: prevInputs.start_est === 0 ? 1 : 0
        }));
        console.log("after ", inputs.start_est);
    };

    const toggleEndEst = () => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            end_est: prevInputs.end_est === 0 ? 1 : 0
        }));
    };

    return (
        <SimpleBox sx={{ width: '75%', alignItems: 'center', justifyContent: 'center', m: '0 auto', mt: 5 }}>
            <Box component="form" onSubmit={handleSubmit} sx={{p: 3}}>
                <InputLabel id="main-cat-label">
                    <h3>{event ? 'Esemény szerkesztése' : 'Esemény hozzáadás'}</h3>
                </InputLabel>
                <TextField
                    fullWidth
                    id="title"
                    label="Esemény neve"
                    name="title"
                    value={inputs.title}
                    onChange={handleChange}
                />
                <div sx={{mt: 3}}>
                    <Editor
                        apiKey='5x3x33fujcfwfu9u3bbptad4mjd1zizoix52ui4e3zuipmfo'
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        value={inputs.description}
                        onEditorChange={(newValue, editor) => {
                            setInputs(values => ({...values, description: newValue}));
                            editorRef.current = editor; // Store the editor instance
                        }}
                        init={{
                            height: 500,
                            menubar: 'file edit view insert format tools table help',
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
                                'importcss', 'autosave', 'directionality', 'codesample', 'emoticons',
                                'accordion'
                            ],
                            toolbar: 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | insertfile image | link image | table media | lineheight outdent indent | forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                            image_advtab: true,
                            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                            noneditable_class: 'mceNonEditable',
                            toolbar_mode: 'sliding',
                            contextmenu: 'link image table',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                            automatic_uploads: true,
                            images_upload_url: '/api/create/postImage',
                            relative_urls: false,            // Disable relative URLs
                            remove_script_host: false,       // Keep the domain part of the URL
                            convert_urls: true,
                        }}
                    />
                </div>

                <Box component="hr" sx={{mt: 3, mb: 3}}/>

                <FormControl fullWidth sx={{mt: 3, mb: 3}}>
                    <InputLabel id="active-label">Státusz</InputLabel>
                    <Select
                        id="active"
                        name="active"
                        labelId="active-label"
                        value={inputs.active}
                        onChange={handleChange}
                        fullWidth
                        label="Státusz"
                    >
                        <MenuItem key={1} value={1}> Aktív </MenuItem>
                        <MenuItem key={2} value={2}> Bal Sáv </MenuItem>
                        <MenuItem key={3} value={3}> Archív </MenuItem>
                        <MenuItem key={0} value={0}> Inaktív </MenuItem>
                    </Select>
                </FormControl>

                <div>
                    <Typography component="h4" sx={{ fontSize: '1.5rem', fontWeight: 'bold', mt: 3, mb: 1 }}>
                        Meta Description:
                    </Typography>
                    <Editor
                        apiKey='5x3x33fujcfwfu9u3bbptad4mjd1zizoix52ui4e3zuipmfo'
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        value={inputs.meta_description}
                        onEditorChange={(newValue, editor) => {
                            setInputs(values => ({...values, meta_description: newValue}));
                        }}
                        init={{
                            menubar: false,
                            height: 200,
                            noneditable_class: 'mceNonEditable',
                            toolbar_mode: 'sliding',
                            contextmenu: 'link image table',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                        }}
                    />
                </div>

                <div>
                    <Typography component="h4" sx={{ fontSize: '1.5rem', fontWeight: 'bold', mt: 3, mb: 1 }}>
                        Meta Keywords:
                    </Typography>
                    <Editor
                        apiKey='5x3x33fujcfwfu9u3bbptad4mjd1zizoix52ui4e3zuipmfo'
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        value={inputs.meta_keywords}
                        onEditorChange={(newValue, editor) => {
                            setInputs(values => ({...values, meta_keywords: newValue}));
                        }}
                        init={{
                            menubar: false,
                            height: 200,
                            noneditable_class: 'mceNonEditable',
                            toolbar_mode: 'sliding',
                            contextmenu: 'link image table',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                        }}
                    />
                </div>

                <Grid container spacing={5} sx={{mt: 2}}>
                    <Grid item xs={6}>
                        <Button
                            onClick={handleCreate}
                            color="info"
                            fullWidth
                            variant="contained"
                            sx={{mt: 2, p: 2}}
                        >
                            Újként létrehozás
                        </Button>
                    </Grid>

                    <Grid item xs={6}>
                        {event && (
                            <Button
                                type="submit"
                                color="success"
                                fullWidth
                                variant="contained"
                                sx={{mt: 2, mb: 2, p: 2}}
                            >
                                Mentés
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity}/>
        </SimpleBox>
    );
}
