import axios from "axios";
import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Grid from "@mui/material/Grid";
import SimpleBox from "../../util/SimpleBox";

const columns = (handleOpenDialog) => [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'title', headerName: 'Original Name', width: 300 },
    {
        field: 'active',
        headerName: 'State',
        width: 130,
        renderCell: (params) => {
            const stateTitles = {
                0: 'Inaktív',
                1: 'Aktív',
                2: 'Ütemezett',
                3: 'Archív',
            };
            return (
                stateTitles[params.row.active]
            );
        }
    },
    {
        field: 'category',
        headerName: 'Category',
        width: 130,
        renderCell: (params) => {
            const categoryTitles = {
                0: 'Battlepass',
                1: 'Event',
                2: 'News',
                3: 'DLC',
                4: 'Patch Note',
                5: 'Dev-Talk',
            };
            return (
                categoryTitles[params.row.category]
            );
        }
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        renderCell: (params) => (
            <>
                <Button variant="contained" color="info" component={Link} to={`/editEvent/${params.row.id}`} sx={{ mr: "10px" }}>Edit</Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleOpenDialog(params.row.id)}
                >
                    Delete
                </Button>
            </>
        ),
    },
];

export default function ListEvents({ games }) {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteId, setDeleteId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const getGameTitleById = (id) => {
        return games.find(game => String(game.id) === String(id))?.title || "Unknown Game";
    };

    const handleOpenDialog = (id) => {
        setDeleteId(id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDeleteId(null);
    };

    const handleDelete = () => {
        if (deleteId !== null) {
            axios.delete(`/api/general/deleteEvent/${deleteId}`)
                .then(() => {
                    getEvents();
                    handleCloseDialog();
                })
                .catch((error) => {
                    console.error("There was an error deleting the event!", error);
                });
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    const getEvents = () => {
        setLoading(true);
        axios.get('/api/get/events')
            .then((response) => {
                console.log('Fetched events:', response.data); // Debugging line
                setEvents(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("There was an error fetching the events!", error);
                setLoading(false);
            });
    };

    return (
        <SimpleBox sx={{ p: 3, width: '75%', alignItems: 'center', justifyContent: 'center', m: '0 auto', mt: 5 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mb: 3 }}>
                    <div style={{ height: 1000, width: '100%' }}>
                        {loading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <DataGrid
                                rows={events}
                                columns={columns(handleOpenDialog, getGameTitleById)}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10]}
                                disableSelectionOnClick
                            />
                        )}
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                        >
                            <DialogTitle>Confirm Deletion</DialogTitle>
                            <DialogContent>
                                Are you sure you want to delete this event?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog}>Cancel</Button>
                                <Button onClick={handleDelete} color="error">Delete</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Grid>
            </Grid>
        </SimpleBox>
    );
}
