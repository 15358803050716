import * as React from 'react';
import {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Button from '@mui/material/Button';
import RequireAdmin from "./auth/RequireAdmin";
import AdminMenu from "./menu/AdminMenu";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Drawer, IconButton, Switch, Tooltip} from "@mui/material";
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useTheme} from "@mui/styles";
import {Box, color, styled} from "@mui/system";
import TextToSpeech from "./util/TextToSpeech";
import NightModeSwitch from "./util/NightModeSwitch";
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import CropFreeSharpIcon from '@mui/icons-material/CropFreeSharp';
import CasinoIcon from '@mui/icons-material/Casino';
import Avatar from "@mui/material/Avatar";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AdjustIcon from '@mui/icons-material/Adjust';
import CycloneIcon from '@mui/icons-material/Cyclone';
import HighlightIcon from '@mui/icons-material/Highlight';
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';
import GradientIcon from '@mui/icons-material/Gradient';
import StringAvatar from "./user/StringAvatar";
import {useSessionData} from "./auth/SessionDataProvider";
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import axios from "axios";
import UserAvatar from "./user/UserAvatar";

const drawerWidth = 350;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  width: 50,
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const HeaderLoggedIn = ({
                            adminMode,
                            toggleAdminMode,
                            settings,
                            setSettings,
                            drawerOpen,
                            setDrawerOpen,
                            textToSpeech = '',
                            darkMode,
                            toggleDarkMode,
                            isSidebarVisible,
                            toggleSidebarVisible,
                            colorPalette,
                            setColorPalette,
                            hexToShadow,
                            shadows,
                            toggleShadows,
}) =>{
  const theme = useTheme();
  const location = useLocation();
  const [zoom, setZoom] = useState(100);
  const { sessionData } = useSessionData();

  const changeDrawerState = () => {
    setDrawerOpen(!drawerOpen);
  }

    const zoomIn = () => {
        setZoom((prevZoom) => Math.min(prevZoom + 10, 200)); // Increase zoom by 10%, capped at 200%
        updateZoomLevel();
    };

    const zoomOut = () => {
        setZoom((prevZoom) => Math.max(prevZoom - 10, 50)); // Decrease zoom by 10%, no lower than 50%
        updateZoomLevel();
    };

    const zoomZero = () => {
        setZoom(100);
        updateZoomLevel();
    }

    const updateZoomLevel = () => {
        document.body.style.zoom = `${zoom}%`;
    };

    const shakeThingsUp = () => {
        const newColorPalette = { ...colorPalette }; // Create a copy of the existing colorPalette
        for (const key in newColorPalette) {
            if (key === 'paper' || key === 'secondary' || key === 'appbar' || key === 'sidebar' ) {
                newColorPalette[key] = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
            };
        }
        setColorPalette(newColorPalette);
    }

    const resetTheme = () => {
        setColorPalette({
            background: '#FFFFFF',
            secondary: '#1A2533',
            paper: '#E5E5E5',
            text: '#000000',
            text_on_dark: '#FFFFFF',
            text_on_secondary: '#FFFFFF',
            dark: '#181818',
            appbar: '#790000',
            sidebar: '#181818',
            sanguine: '#790000',
            shadow: '#000000',
            info: '#2196f3',
            warning: '#ff9f00',
            success: '#4caf50',
            error: '#a80000',
        });
    }

    const LogOut = ()=>{
        localStorage.removeItem('email');
        localStorage.removeItem('userName');
        localStorage.removeItem('rights');
        localStorage.clear();
        axios.post('/api/user/close_session')
            .then(()=>{
                window.location.href = '/';
            })
    }

    return(
        <>
        {/*<AppBar position="fixed" open={true} sx={{ mb: 30 }}>*/}
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top top-menu" style={{ backgroundColor: colorPalette.appbar, opacity: "97%", boxShadow: hexToShadow(colorPalette.appbar, 0.9, 50) }}>
          <div className="container-fluid">
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li>
                {/*  <Container>*/}
                {/*      <img*/}
                {/*          src={process.env.PUBLIC_URL + '/images/favicon.png'}*/}
                {/*          alt="favicon"*/}
                {/*          title="logo"*/}
                {/*          width="32px"*/}
                {/*          onClick={() => setSettingsOpen(true)}*/}
                {/*      />*/}
                {/*    <SettingsDialog*/}
                {/*        open={settingsOpen}*/}
                {/*        setOpen={setSettingsOpen}*/}
                {/*    />*/}
                {/*  </Container>*/}
                    <Tooltip title="Menü" arrow sx={{ zIndex: 750}}>
                      <IconButton
                            color="inherit"
                          aria-label="open drawer"
                          onClick={changeDrawerState}
                          edge="start"
                          sx={{ mr: 2 }}
                      >
                          {drawerOpen ? <ChevronLeftIcon color={colorPalette.text} /> : <MenuIcon color={colorPalette.text} />}
                      </IconButton>
                    </Tooltip>
                </li>
                  {/*<li>*/}
                  {/*    <Tooltip title="Világos / Sötét" arrow>*/}
                  {/*      <NightModeSwitch checked={darkMode} toggleDarkMode={toggleDarkMode} colorPalette={colorPalette} />*/}
                  {/*    </Tooltip>*/}
                  {/*</li>*/}
                <li>
                    <Tooltip title="Oldalsáv / Olvasómód" arrow sx={{ zIndex: 750}}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleSidebarVisible}
                            disabled={sessionData.isSidebarDisabled}
                            edge="start"
                            sx={{ ml: 2 }}
                        >
                            {isSidebarVisible ? <SpaceBarIcon /> : <CropFreeSharpIcon color={"warning"}/>}
                        </IconButton>
                    </Tooltip>
                    {/*<Tooltip title="Random teszt" arrow sx={{ zIndex: 750}}>*/}
                    {/*    <IconButton*/}
                    {/*        color="inherit"*/}
                    {/*        aria-label="open drawer"*/}
                    {/*        onClick={() => (window.location.href = `/test/9`)}*/}
                    {/*        edge="start"*/}
                    {/*        sx={{ ml: 2 }}*/}
                    {/*    >*/}
                    {/*        <CasinoIcon/>*/}
                    {/*    </IconButton>*/}
                    {/*</Tooltip>*/}
                </li>
                <li>
                    <TextToSpeech text={textToSpeech}/>
                </li>
                <li>
                    <Tooltip title="Kicsinyítés" arrow sx={{ zIndex: 750}}>
                      <IconButton
                          color="inherit"
                          aria-label="Zoom Out"
                          edge="start"
                          onClick={zoomOut}
                          sx={{ ml: 2, mr: 2 }}
                      >
                          <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                      <IconButton
                          color="inherit"
                          aria-label="Standard view"
                          edge="start"
                          onClick={zoomZero}
                          sx={{ mr: 2 }}
                      >
                          <AdjustIcon />
                      </IconButton>
                    <Tooltip title="Nagyítás" arrow sx={{ zIndex: 750}}>
                      <IconButton
                          color="inherit"
                          aria-label="Zoom In"
                          edge="start"
                          onClick={zoomIn}
                          sx={{ mr: 2 }}
                      >
                          <AddIcon />
                      </IconButton>
                    </Tooltip>
                </li>
                  {/*<li>*/}
                  {/*    <Tooltip title="Színkeverés" arrow sx={{ zIndex: 750}}>*/}
                  {/*        <IconButton*/}
                  {/*            color={colorPalette.text}*/}
                  {/*            aria-label="Zoom In"*/}
                  {/*            edge="start"*/}
                  {/*            onClick={shakeThingsUp}*/}
                  {/*            sx={{ mr: 2 }}*/}
                  {/*        >*/}
                  {/*            <CycloneIcon />*/}
                  {/*        </IconButton>*/}
                  {/*    </Tooltip>*/}
                  {/*    <Tooltip title="Árnyékok" arrow sx={{ zIndex: 750}}>*/}
                  {/*        <IconButton*/}
                  {/*            color="inherit"*/}
                  {/*            aria-label="Shadows"*/}
                  {/*            edge="start"*/}
                  {/*            onClick={toggleShadows}*/}
                  {/*            sx={{ mr: 2 }}*/}
                  {/*        >*/}
                  {/*            {shadows ? <HighlightIcon color={"warning"} /> : <FlashlightOnIcon />}*/}
                  {/*        </IconButton>*/}
                  {/*    </Tooltip>*/}
                  {/*    <Tooltip title="Alap téma" arrow sx={{ zIndex: 750}}>*/}
                  {/*        <IconButton*/}
                  {/*            color="inherit"*/}
                  {/*            aria-label="Reset"*/}
                  {/*            edge="start"*/}
                  {/*            onClick={resetTheme}*/}
                  {/*            sx={{ mr: 2 }}*/}
                  {/*        >*/}
                  {/*            <GradientIcon/>*/}
                  {/*        </IconButton>*/}
                  {/*    </Tooltip>*/}
                  {/*</li>*/}
                <li style={{position: 'absolute', right: '20px' }}>
                    <UserAvatar user={sessionData.session_users[sessionData.userId]} />
                </li>
              </ul>
            </div>
          </div>
        </nav>
            <div style={{ display: 'flex' }}>
          <Drawer
              sx={{
                width: 85,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: 85,
                  boxSizing: 'border-box',
                  backgroundColor: colorPalette.sidebar,
                  boxShadow: hexToShadow(colorPalette.sidebar),
                },
              }}
              variant="persistent"
              anchor="left"
              open={!drawerOpen}
          >
            <DrawerHeader>
              <IconButton onClick={changeDrawerState}>
                {theme.direction === 'ltr' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
            <Button component={Link} to={"/home"} variant={location.pathname.includes('home') ? "contained" : "outlined"} sx={{ mr: 1, ml: 1 }}><HomeIcon /></Button><br/>
            <Button component={Link} to={"/course/manage/0"} variant={location.pathname.includes('course') ? "contained" : ""} sx={{ mr: 1, ml: 1 }}><SchoolIcon /></Button><br/>
            <Button component={Link} to={"/user/message/fresh"} variant={location.pathname.includes('message') ? "contained" : ""} sx={{ mr: 1, ml: 1 }}><EmailOutlinedIcon /></Button><br/>
            <Button component={Link} to={"/view/groups/all"} variant={location.pathname.includes('group') ? "contained" : ""} sx={{ mr: 1, ml: 1 }}><GroupsIcon /></Button><br/>
            <Button component={Link} to={"/user/profile"} variant={location.pathname.includes('profile') ? "contained" : ""} sx={{ mr: 1, ml: 1 }}><PersonIcon /></Button><br/>
          </Drawer>
          <Drawer
              sx={{
                width: drawerOpen ? drawerWidth : 0,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerOpen ? drawerWidth : 0,
                  boxSizing: 'border-box',
                  backgroundColor: colorPalette.sidebar,
                  boxShadow: hexToShadow(colorPalette.sidebar, 0.7, 30),
                },
              }}
              variant="persistent"
              anchor="left"
              open={drawerOpen}
          >
            <DrawerHeader>
              <IconButton onClick={changeDrawerState}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
              <Button component={Link} to={"/home"} variant={location.pathname.includes('home') ? "contained" : "outlined"} sx={{ mr: 2, ml: 2, mb: 2, justifyContent: 'space-between'}} endIcon={<HomeIcon />}>Home</Button>
              <Button component={Link} to={"/course/manager"} variant={location.pathname.includes('course') ? "contained" : "outlined"} sx={{ mr: 2, ml: 2, mb: 2, justifyContent: 'space-between'}} endIcon={<SchoolIcon/>}>Tananyagok</Button>
              <Button component={Link} to={"/view/manager/0"} variant={location.pathname.includes('/view/manager') ? "contained" : "outlined"} sx={{ mr: 2, ml: 2, mb: 2, justifyContent: 'space-between'}} endIcon={<SchoolIcon/>}>Feladatok</Button>
              <Button component={Link} to={"/user/message/fresh"} variant={location.pathname.includes('message') ? "contained" : "outlined"} sx={{ mr: 2, ml: 2, mb: 2, justifyContent: 'space-between'}} endIcon={<EmailOutlinedIcon />}>Üzenetek</Button>
              <Button component={Link} to={"/view/groups/all"} variant={location.pathname.includes('group') ? "contained" : "outlined"} sx={{ mr: 2, ml: 2, mb: 2, justifyContent: 'space-between'}} endIcon={<GroupsIcon />}>Csoportok</Button>
              <Button component={Link} to={"/user/profile"} variant={location.pathname.includes('profile') ? "contained" : "outlined"} sx={{ mr: 2, ml: 2, mb: 2, justifyContent: 'space-between'}} endIcon={<PersonIcon />}>Profilom</Button>
              <Button component={Link} onClick={LogOut} to={"/"} variant={"outlined"} sx={{ mr: 2, ml: 2, mb: 2, justifyContent: 'space-between'}} endIcon={<ExitToAppIcon />}>Kijelentkezés</Button>
              <RequireAdmin>
                  <FormControlLabel control={<Switch />} checked={adminMode} label="Admin" onClick={toggleAdminMode} sx={{ mr: 2, ml: 2 }}/><br/>
                  <AdminMenu /><br/>
              </RequireAdmin>
          </Drawer>
            </div>
        {/*</AppBar>*/}
    </>
    )
}

export default HeaderLoggedIn;
