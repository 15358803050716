import React from 'react';
import Grid from "@mui/material/Grid";
import FeedCard from "./FeedCard";

const EventFeed = ({ events }) => {
    return (
        <Grid container spacing={2}>
            {events.length > 0 ? (
                events.map(event => (
                    <Grid item xs={12} key={event.id} sx={{ mb: 2 }}>
                        <FeedCard event={event} />
                    </Grid>
                ))
            ) : (
                <p>No events available.</p>
            )}
        </Grid>
    );
};

export default EventFeed;
