import React, { useEffect, useState } from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useSessionData } from "../auth/SessionDataProvider";
import SimpleBox from "../util/SimpleBox";
import { Editor } from "@tinymce/tinymce-react";

export default function AddNewUser() {
    const { sessionData } = useSessionData();
    const [open, setOpen] = useState(false);
    const [inputs, setInputs] = useState({
        email: '',
        rights: 1,
        referer: sessionData.userId,
        message: '<h3>Kedves Felhasználó,</h3>' +
            'A Felhasználói fiókod a Korrepetáció.hu rendszerébe elkészült!<br/>' +
            'A bejelentkezéshez itt tudod használni a google accountodat:<br/> https://tanulas.korrepetacio.hu<br/><br/>' +
            'Üdvözlettel:<br/>' +
            'Korrepetacio.hu Csapat'
    });
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }

        try {
            const response = await axios.post('/api/create/user', formData);
            console.log(response);
            if (response.data !== false) {
                setOpen(true);
                setInputs((prevInputs) => ({
                    ...prevInputs,
                    email: '',
                    rights: 1,
                    referer: sessionData.userId,
                    message: '<h3>Kedves Felhasználó,</h3>' +
                        'A Felhasználói fiókod a Korrepetáció.hu rendszerébe elkészült!<br/>' +
                        'A bejelentkezéshez itt tudod használni a google accountodat:<br/> https://tanulas.korrepetacio.hu<br/><br/>' +
                        'Üdvözlettel:<br/>' +
                        'Korrepetacio.hu Csapat'
                }));
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    return (
        <>
            <SimpleBox sx={{ p: 2, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                <Box component="form" onSubmit={handleSubmit} sx={{ width: '95%'}}>
                    <InputLabel id="main-cat-label">
                        <h3>Felhasználó Hozzáadás</h3>
                    </InputLabel>

                    <TextField
                        label="Email cím"
                        name="email"
                        type="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={inputs.email}
                        onChange={handleChange}
                        required
                    />

                    <h4>Meghívó üzenet:</h4>
                    <Editor
                        apiKey='5x3x33fujcfwfu9u3bbptad4mjd1zizoix52ui4e3zuipmfo'
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        value={inputs.message}
                        onEditorChange={(newValue, editor) => {
                            setInputs(values => ({...values, message: newValue}));
                        }}
                        init={{
                            menubar: false,
                            height: 200,
                            noneditable_class: 'mceNonEditable',
                            toolbar_mode: 'sliding',
                            contextmenu: 'link image table',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                        }}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 5, mb: 2, p: 2 }}
                    >
                        Hozzáadás
                    </Button>
                </Box>
            </SimpleBox>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Felhasználó hozzáadás</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Felhasználó sikeresen hozzáadva
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="success">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
