import React from 'react';
import {
    Typography, Divider,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import SimpleBox from "../../util/SimpleBox";

const FeedCard = ({ event}) => {
    return (
        <SimpleBox>
            <Grid container spacing={1} className="wrap">
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        component="div"
                        gutterBottom
                        sx={{
                            textShadow: '0px 2px 10px rgba(0, 0, 0, 1)' // Applying textShadow with RGBA color
                        }}
                    >
                        <div
                            dangerouslySetInnerHTML={{__html: event.title}} // Use dangerouslySetInnerHTML to render HTML content
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ border: '1px solid white', m: 1 }} />
                </Grid>
                <Grid item xs={12} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    ml: 2,
                    mr: 2
                }}>
                    <div
                        dangerouslySetInnerHTML={{__html: event.description}} // Use dangerouslySetInnerHTML to render HTML content
                    />
                </Grid>

            </Grid>
        </SimpleBox>
    );
};

export default FeedCard;
