import * as React from 'react';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import SignInWithGoogle from "./auth/SignInWithGoogle";
import {useSessionData} from "./auth/SessionDataProvider";
import SchoolIcon from "@mui/icons-material/School";
import Typography from "@mui/material/Typography";
import MainPage from "./modules/home/MainPage";

const Header = ({ colorPalette }) =>{
    const { hexToShadow, isOnPath } = useSessionData();
    return (
      <>
          <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top top-menu" style={{ backgroundColor: colorPalette.appbar, opacity: "97%", boxShadow: hexToShadow(colorPalette.appbar, 0.9, 50) }}>
              <div className="container-fluid">
                  <div className="collapse navbar-collapse" id="navbarText">
              <Grid container sx={{ justifyContent: 'space-between', p: 3 }}>
                  <Grid item flex={1}>
                      <Typography variant={"h5"}>
                      <img src={process.env.PUBLIC_URL + '/images/favicon.png'} alt="favicon" title="logo" width="32px" style={{ marginRight: '10px' }}/>
                      Korrepetacio.hu
                      </Typography>
                  </Grid>
                  <Grid item flex={3}>
                      {/*<Button*/}
                      {/*    component={Link}*/}
                      {/*    to={"/search"}*/}
                      {/*    variant={isOnPath('search') ? "contained" : "outlined"}*/}
                      {/*    sx={{mr: 2, ml: 2, justifyContent: 'space-between'}}*/}
                      {/*    endIcon={<SchoolIcon sx={{ ml: 2 }}/>}*/}
                      {/*>*/}
                      {/*    Tanár kereső*/}
                      {/*</Button>*/}
                  </Grid>
                  <Grid item flex={1}>
                    <SignInWithGoogle/>
                  </Grid>
              </Grid>
            </div>
          </div>
        </nav>
          <MainPage/>
    </>
    )
}

export default Header;
