import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import EventFeed from "./eventFeed";
import axios, {get} from "axios";

export default function MainPage() {
    const [ events, setEvents ] = useState([]);

    useEffect(() => {
        getEvents();
    }, [])

    function getEvents() {
        axios.get('/api/get/activeEvents')
            .then((result) => {
                if (result.data !== false) {
                    console.log("data: ", result.data);
                    setEvents(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={3}>
                {events.map((item) => (
                    item.active === 2 && (
                        <Grid item xs={12} key={item.id}>
                            <EventFeed events={[item]} />
                        </Grid>
                    )
                ))}
            </Grid>
            <Grid item xs={9}>
                {events.map((item) => (
                    item.active === 1 && (
                        <Grid item xs={12} key={item.id}>
                            <EventFeed events={[item]} />
                        </Grid>
                    )
                ))}
            </Grid>
        </Grid>
    );
}
